import {
  getSystemSettings,
  setSystemSetting
} from '@/methods/systemSettings';
import { ElNotification } from 'element-plus';
import { getDenyPrivilegeObject, getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  systemSettingList: [],
};

const getters = {
  getSystemSettings(state) {
    return state.systemSettingList;
  },
};

const mutations = {
  UPDATE_SYSTEM_SETTING_LIST(state, data) {
    state.systemSettingList = data;
  },
};

const actions = {
  getSystemSettings({ commit, state }) {
    getSystemSettings()
      .then((response) => {
          if ('success' in response.data && response.data.success) {
            commit('UPDATE_SYSTEM_SETTING_LIST', response.data.data);
            commit('privilege/UPDATE_SETTINGS_SYSTEM_PRIVILEGE', response.data.privilege, {root: true});
          }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_SYSTEM_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  setSystemSetting({ commit, dispatch, state }, payload) {
    setSystemSetting(payload)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          dispatch('getSystemSettings');
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });

        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
