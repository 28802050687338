import { createStore } from 'vuex';
/* eslint-disable */
import Auth from '@/store/modules/Auth';
import Menu from '@/store/modules/Menu';
import Storage from '@/store/modules/Storage';
import Collection from '@/store/modules/Collection';
import CooperationType from '@/store/modules/CooperationType';
import Capacity from '@/store/modules/Capacity';
import Partner from '@/store/modules/Partner';
import Inbound from '@/store/modules/Inbound';
import Outbound from '@/store/modules/Outbound';
import DirectionDocuments from '@/store/modules/DirectionDocuments';
import Calendar from '@/store/modules/Calendar';
import BaseCalendar from '@/store/modules/BaseCalendar';
import Shipment from '@/store/modules/Shipment';
import Return from '@/store/modules/Return';
import Ratings from './modules/Ratings';
import RatingMatrix from './modules/RatingMatrix';
import BookingSlot from './modules/BookingSlot';
import TimeMatrix from './modules/TimeMatrix';
import Gates from './modules/Gates';
import Booking from './modules/Booking';
import BookingComment from './modules/BookingComment';
import BookingTag from './modules/BookingTag';
import PartnerRestrictions from './modules/PartnerRestrictions';
import SmiOt from './modules/SmiOt';
import Incident from './modules/Incident';
import Privilege from './modules/Privilege';
import LamodaCarRegistry from './modules/LamodaCarRegistry';
import CarRegistry from './modules/CarRegistry';
import Direction from './modules/Direction';
import BreakTime from './modules/BreakTime';
import ChangeLog from './modules/ChangeLog';
import SystemSettings from './modules/SystemSettings';
/* eslint-enable */

export default createStore({
  modules: {
    auth: Auth,
    menu: Menu,
    storage: Storage,
    collection: Collection,
    cooperationType: CooperationType,
    capacity: Capacity,
    partner: Partner,
    inbound: Inbound,
    outbound: Outbound,
    directionDocuments: DirectionDocuments,
    calendar: Calendar,
    shipment: Shipment,
    return: Return,
    ratings: Ratings,
    ratingMatrix: RatingMatrix,
    timeMatrix: TimeMatrix,
    gates: Gates,
    baseCalendar: BaseCalendar,
    bookingSlot: BookingSlot,
    booking: Booking,
    bookingComment: BookingComment,
    bookingTag: BookingTag,
    partnerRestrictions: PartnerRestrictions,
    smiOt: SmiOt,
    incident: Incident,
    privilege: Privilege,
    lamodaCarRegistry: LamodaCarRegistry,
    carRegistry: CarRegistry,
    direction: Direction,
    breakTime: BreakTime,
    changeLog: ChangeLog,
    systemSettings: SystemSettings,
  },
});
